import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step crypto jwk`}</strong>{` -- create JWKs (JSON Web Keys) and manage JWK Key Sets`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step crypto jwk <subcommand> [arguments] [global-flags] [subcommand-flags]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p>{`The `}<strong parentName="p">{`step crypto jwk`}</strong>{` command group provides facilities for creating JWKs
(JSON Web Keys) as defined in RFC7517. It also includes command line utilities
for managing Key Sets and working with encrypted keys.`}</p>
    <p>{`A JWK is a JSON data structure that represents a cryptographic key. The
members of this data structure represent properties of the key, including its
value. A JWK Set is a simple data structure for representing a set of JWKs. A
JWK Set is a JSON object with a "keys" member whose value is an array of JWKs.
Cryptographic algorithms and identifiers for used by JWKs are defined by the
JSON Web Algorithms (JWA) specification in RFC7518. This tool also supports
extensions defined in standards track RFC8037 defining curve and algorithm
identifiers for Edwards-curve Digital Signatures.`}</p>
    <p>{`JWKs and JWK Sets are used in the JSON Web Signature (JWS; RFC7515) and JSON
Web Encryption (JWE; RFC7516) specifications for signing and encrypting JSON
data, respectively.`}</p>
    <h2>{`Examples`}</h2>
    <p>{`Create a JWK using the default parameters (NIST P-256 curve):`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step crypto jwk create pub.json priv.json
`}</code></pre>
    <p>{`Add the previous public keys to a JWK Set (JWKS):`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ cat pub.json | step crypto jwk keyset add ks.json
`}</code></pre>
    <p>{`List the keys in a JWKS:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step crypto jwk keyset list ks.json
ZI9Ku2jJQL84ewxVn8C_67iDaTN_DFTXE9Gypo6-3YE
L38TOXsig8h6FeBOos03nFy6iXmwusFcIBBB0ZilahY
`}</code></pre>
    <p>{`Remove a JWK from a JWKS:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step crypto jwk keyset remove ks.json --kid ZI9Ku2jJQL84ewxVn8C_67iDaTN_DFTXE9Gypo6-3YE

$ step crypto jwk keyset list ks.json
L38TOXsig8h6FeBOos03nFy6iXmwusFcIBBB0ZilahY
`}</code></pre>
    <p>{`Extract a JWK from a JWKS:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step crypto jwk keyset find ks.json --kid L38TOXsig8h6FeBOos03nFy6iXmwusFcIBBB0ZilahY
{
  "use": "sig",
  "kty": "EC",
  "kid": "L38TOXsig8h6FeBOos03nFy6iXmwusFcIBBB0ZilahY",
  "crv": "P-256",
  "alg": "ES256",
  "x": "n_vvepi2bAby8LhsmY396msumgs4EQGoNNzar6wtyAc",
  "y": "hDRyGFO3M0-4_4MReiwbwXvh6XL3PMh4BAPu0qnTItM"
}
`}</code></pre>
    <p>{`See the public version of a private JWK:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ cat priv.json | step crypto jwk public
{
  "use": "sig",
  "kty": "EC",
  "kid": "L38TOXsig8h6FeBOos03nFy6iXmwusFcIBBB0ZilahY",
  "crv": "P-256",
  "alg": "ES256",
  "x": "n_vvepi2bAby8LhsmY396msumgs4EQGoNNzar6wtyAc",
  "y": "hDRyGFO3M0-4_4MReiwbwXvh6XL3PMh4BAPu0qnTItM"
}
`}</code></pre>
    <p>{`Create a JWK Thumbprint for a JWK:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ cat priv.json | step crypto jwk thumbprint
L38TOXsig8h6FeBOos03nFy6iXmwusFcIBBB0ZilahY
`}</code></pre>
    <h2>{`Commands`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Usage`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "create/"
              }}>{`create`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`create a JWK (JSON Web Key)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "keyset/"
              }}>{`keyset`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`add, remove, and find JWKs in JWK Sets`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "public/"
              }}>{`public`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`extract a public JSON Web Key (JWK) from a private JWK`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "thumbprint/"
              }}>{`thumbprint`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`compute thumbprint for a JWK`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      